import React, { FC } from "react"
import Search from "components/Search/"
import { useSearchIndex } from "hooks/searchIndex"
import { PageTitle } from "components/Shared/PageTitle"
import styled from "styled-components"
import { typography } from "styles/typography"
import { breakpoint } from "styles/breakpoints"
import { spacing } from "styles/spacing"
import { Container } from "components/Shared/Container"

const StyledPageTitle = styled(PageTitle)`
  font-size: ${typography.size.md};
  margin: ${spacing.lg}px 0;
  @media ${breakpoint.md} {
    font-size: ${typography.size.lg};
  }
  @media ${breakpoint.lg} {
    font-size: ${typography.size.xl};
  }
  @media ${breakpoint.xl} {
    font-size: ${typography.size.xxl};
  }
`

const NotFoundPage: FC = () => {
  const index = useSearchIndex()
  return (
    <Container>
      <StyledPageTitle>
        Woops, sidan du letade efter finns inte.
      </StyledPageTitle>
      <Search searchIndex={index} filter={{ type: "404" }} />
    </Container>
  )
}

export default NotFoundPage
